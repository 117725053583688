.credit-card {
  // border: 1px solid red;
  .Wrap {
    display: flex;
    justify-content: center;
    align-items: center;

   
  }

  .Base {
    height: 15rem;
    max-width: 25rem;
    width: 100%;
    border-radius: 15px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .Inner-wrap {
    background-color: #0c0014;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%230d1838' points='1600%2C160 0%2C460 0%2C350 1600%2C50'/%3E%3Cpolygon fill='%230e315d' points='1600%2C260 0%2C560 0%2C450 1600%2C150'/%3E%3Cpolygon fill='%230f4981' points='1600%2C360 0%2C660 0%2C550 1600%2C250'/%3E%3Cpolygon fill='%231062a6' points='1600%2C460 0%2C760 0%2C650 1600%2C350'/%3E%3Cpolygon fill='%23117aca' points='1600%2C800 0%2C800 0%2C750 1600%2C450'/%3E%3C/g%3E%3C/svg%3E");
    background-size: auto 147%;
    background-position: center;
    position: relative;
    height: 15rem;
    width: 100%;
    border-radius: 13px;
    padding: 13px 20px;
    box-sizing: border-box;
    color: #fff;
   
    
  }

  .bank-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-size:clamp(1rem, 2vw, 1.2rem);
    }
    // .logo {
    //   width: 2.6rem;
    //   height: 2.6rem;
    //   // border: 2px solid #ffff00;
    // }
  }

  .chip-set {
    width: 3rem;
    height: 3rem;
    position: relative; 
    margin: 1rem 0;
    img {
      width: 100%;
    }
  }

  p {
    margin: 0;
    font-size: 1.8em;
  }
  .Card-number {
    display: flex;
    font-size: 0.6rem;
    justify-content: center;
    color: rgba(255, 255, 255, 0.9);
   
  }

  ul {
    padding: 0;
  }

  ul li {
    list-style: none;
    float: left;
    margin: 0px 10px;
    font-size: 2em;
  }

  .Expire {
    font-size: 0.65em;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
  }

  .Expire h4 {
    font-weight: 400;
    color: #aaa;
    margin: 0;
    text-transform: uppercase;
  }

  .Expire p {
    font-size: 1.55em;
    color: rgba(255, 255, 255, 0.9);
  }

  .Name h3 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.85);
  }

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    .footer-logo {
      width: 4rem;
      height: 2.2rem;
    }
  }
}
