.UserLogin {
  height: 100vh;
  background: var(--background-color);
  font-family: "Poppins";
  padding-bottom: 2rem;
}
.backgroundText {
  font-style: normal;
  font-weight: 275;
  font-size: 1rem clamp(0.9rem, 2vw, 1rem);
  line-height: clamp(1.75rem, 2vw, 1.875rem);
  letter-spacing: 0.195em;
  color: #fffbf1;
  max-width: 90%;
  margin: auto;
  padding-top: clamp(2.3rem, 2vw, 2.5rem);
  padding-bottom: clamp(0.5rem, 2vw, 0.9rem);
}
.UserLoginContainer {
  max-width: 90%;
  margin: auto;
  background-color: var(--white-color);
  border-right: 13px solid var(--tertiary-color);
  border-radius: 0.3125rem;
  padding: 2rem 1rem;
  margin-top: 1rem;
}
.pageTitle {
  color: var(--background-color);
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: clamp(1.1rem, 2vw, 1.185rem);
  align-items: center;
  margin: 0.5rem 0;
}
.loginForm {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.75rem;
}
.rememberSection {
  display: flex;
  flex-direction: row;
  grid-gap: clamp(0.5rem, 2vw, 0.8rem);
  align-items: center;
  padding-top: clamp(0.8rem, 2vw, 2rem);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
}
.rememberChecked {
  flex: 1;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: clamp(0.86rem, 2vw, 0.875rem);
  line-height: 18px;
  color: var(--text-color);
  text-align: justify;
  white-space: nowrap;
}
.forgetPassword {
  color: var(--tertiary-color);
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: clamp(0.8rem, 2vw, 0.875rem);
  line-height: 18px;
  text-align: justify;
  text-decoration: none;
  white-space: nowrap;
}

// Media Queries
@media (min-width: 41.25rem) {
  .UserLoginContainer {
    width: unset;
    width: 58%;
    padding: unset;
    padding: 20px 30px;
  }
}
@media (min-width: 50.875rem) {
  .UserLoginContainer {
    width: unset;
    width: 55%;
    padding: 50px 50px;
  }
}
@media (min-width: 60.25rem) {
  .UserLoginContainer {
    width: 45%;
  }
}
