@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato");

:root {
  --background-color: #00387a;
  --tertiary-color: #f26522;
  --white-color: #ffffff;
  --border-gray: #a1b4cd;
  --label-color: #646f7e;
  --secondary-color: #5d6e9a;
  /* 
  --secondary-color: #5d6e9a;
  --text-color: #344357;
  --background-color: #00387a;
  --fade-text: rgba(52, 67, 87, 0.8);
  --image-border: #bdbdbd;
  --profile-view-text: #373f53;
  --dashboard-gray: #868686;
  --alert-color: #f34237;
  --danger-color: #eb5757;
  --green-color: #27ae60;
  --light-gray: #4f4f4f;
  --account-border: #e8e8e8;
  --grant-color: #373f53;
  --main-color: #1d84b5;
  --color-dark: #1d89bf;
  --election-card-green: #219653;
  --button-background: rgba(255, 255, 255, 0.1);
  --voting-border: #c3cada; */
}
/* Custom CheckBox */
.termsChecked {
  display: block;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.checkmark {
  border: 1px solid var(--tertiary-color);
  position: absolute;
  top: 0;
  left: 0;
  height: 1.2rem;
  width: 1.2rem;
  background-color: var(--white-color);
  border-radius: 2px;
}

.termsChecked input:checked ~ .checkmark {
  background-color: var(--tertiary-color);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.termsChecked input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.termsChecked .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid var(--white-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
.formLabel {
  text-transform: uppercase;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 575;
  font-size: clamp(0.7rem, 2vw, 0.75rem);
  align-items: center;
  letter-spacing: 0.8px;
  color: var(--label-color);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

/* Global Styling for form Input*/
.formInput {
  outline: none;
  width: 100%;
  background: var(--white-color);
  border: 1px solid var(--border-gray);
  padding: 10px;
  border-radius: 5px;
  color: var(--background-color);
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 595;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  margin-top: 0.1rem;
}
