.loader-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.loadingio-spinner-rolling-57eevzpp4c {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-rrwx22cufba {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */

  @keyframes ldio-rrwx22cufba {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}
.ldio-rrwx22cufba div {
  position: absolute;
  width: 70px;
  height: 70px;
  border: 10px solid #0570ba;
  border-top-color: transparent;
  border-radius: 50%;
  animation: ldio-rrwx22cufba 1s linear infinite;
  top: 100px;
  left: 100px;
}
