.left {
  text-align: center;
  padding: 2rem;

  p {
    margin: 1rem;
  }

  form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 30px;
  }

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  button {
    width: 150px;
    padding: 10px;
    border: none;
    background-color: teal;
    display: block;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
  }
}
