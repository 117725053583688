.statistics_home {
  .homeContainer_stat {
    margin-top: 2rem;
    flex: 6;

    .card-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .widgets {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 20px;
      gap: 20px;
    }
    .chartsStatistics {
      display: flex;
      padding: 20px;
      flex-direction: column;
      gap: 20px;
      
      // @media (max-width: 1000px){
      //   flex-direction: column;
      // }
      
    }

    .chartsStatistics {
      padding: 5px 20px;
    }

    .listContainerStatistics {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;
      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
    }
  }
}
