.formLabel {
  text-transform: uppercase;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 575;
  font-size: clamp(0.7rem, 2vw, 0.75rem);
  align-items: center;
  letter-spacing: 0.8px;
  color: var(--label-color);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

/* Global Styling for form Input*/
.form_Input {
  outline: none;
  width: 100%;
  background: var(--white-color);
  border: 1px solid var(--border-gray);
  padding: 10px;
  border-radius: 5px;
  color: var(--background-color);
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 595;
  margin-top: 0.1rem;

  
 
}
.form_Input:hover {
  border: 1px solid var(--tertiary-color);
}
