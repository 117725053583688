.PasswordInput {
  background: var(--white-color);
  height: 100%;

  .passwordContainer {
    width: 100%;
    position: relative;

    .passwordIcon {
      color: var(--background-color);
      size: 30px;
      position: absolute;
      top: 10px;
      right: 8px;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
