.adminSidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 200px;
  background-color: var(--background-color);

  @media (max-width: 1000px){
    display: none;
   }

   .top {
    padding: 10px;
    border-bottom: 1px solid white;
     .logo {
       font-size: 20px;
       font-weight: bold;
       color: white;
     }
   }

   .center {
    margin-top: 20px;
    
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 10px;
        font-weight: bold;
        color: #fff;
        margin-top: 15px;
        margin-bottom: 5px;
        padding-left: 10px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 10px 5px;
        cursor: pointer;
        padding-left: 10px;

        &:hover {
          background-color: #ece8ff;
          .icon{
            color: #000;
          }
          span{
            color: #000;
          }
        }

        .icon {
          font-size: clamp(20.8px, 2vw, 23px);
          color: #fff;
        }

        span{
            font-size: clamp(13px, 2vw, 15px);
            font-weight: 600;
            color: #fff;
            margin-left: 10px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #7451f8;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }
}
