.Adminham{
   
    .menu-toggle{
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .bar{
            width: 25px;
            height: 3px;
            background-color: black;
            margin: 4px 0;
            transition: 0.4s;
            
        }
    }
    .menu-items{
        padding: 0;
        margin: 0;
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        max-width: 280px;
        background-color: #f9f9f9;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
    }
}