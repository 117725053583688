.newClient {
  .newContainer {
    flex: 6;
    .topTansfer,
    .bottomTansfer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: gray;
        font-size: clamp(1.15rem, 2vw, 1.25rem);
        margin-bottom: 2rem;
      }

      .left {
        text-align: center;

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .right {
        form {
          display: grid;
          grid-template-columns: auto auto;
          gap: 30px;

          .formInput {
            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            .addon {
              display: flex;
              align-items: center;

              p {
                padding: 0.28rem;
                font-size: 0.97rem;
                color: #fff;
                background-color: #0080ff;
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
              }
              input {
                border: 1px solid #2a5581;
              }
            }
            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
            }
            select {
              width: 100%;
              padding: 0.5rem;
            }
          }
        }
      }
      .amount-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        text-align: left;
        // border: 1px solid red;
        button {
          width: 150px;
          padding: 10px;
          border: none;
          background-color: teal;
          display: block;
          color: white;
          font-weight: bold;
          cursor: pointer;
          margin-top: 10px;
        }
      }
    }
    .topTansfer {
      display: block;
      padding: 1.5rem 30px;
      // input {
      //   margin-left: 1rem;
      // }
      h1 {
        text-align: center;
      }
      .info-card {
        .ip-card {
          h3 {
            font-size: 1rem;
          }
          p {
            font-size: 0.8rem;
            // width: 35rem;
            margin: 0.7rem 0;
          }
         
        }
      }
    }
    .bottomTansfer {
      flex-direction: column;
    }
  }
  .alert {
    color: #001B2E;
  }
  @media (max-width: 840px) {
    .newContainer {
      .bottomTansfer {
        .right {
          form {
            grid-template-columns: auto;
          }
        }
        .amount-card {
          flex-direction: column;
        }
      }
    }
  }
}
