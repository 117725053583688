.Button {
  height: 100%;

  .button {
    border: none;
    border-radius: 3px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 550;
    font-size: clamp(0.8rem, 2vw, 0.875rem);
    align-items: center;
    text-align: center;
    color: var(--white-color);
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    cursor: pointer;
    white-space: nowrap;
  }
}
