.client-home {
  .home_Container {
    flex: 6;
     @media (max-width: 1000px) {
    align-items: center;
    width: 100%;
    
  }
    .card-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      margin-top: 1rem;
      align-items: center;

      .widgets {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 20px;
        gap: 20px;
        // border: 1px solid red;
      }
    }

    
    .charts {
      display: flex;
      padding: 20px;
      gap: 20px;
    }

    .charts {
      padding: 5px 20px;
    }

    .listContainer_home {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;
      overflow-x: auto; 
      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
    }
    @media (max-width: 1000px){
        margin-bottom: 2rem;
    }
  }

  @media (max-width: 1080px) {
   .home_Container{
    .card-details {
      grid-template-columns: 1fr;
      gap: 1rem;
      align-items: center;
      
    }
   }
  }
  @media (max-width: 760px) {
   .home_Container{
    .card-details {
      .widgets {
        grid-template-columns: 1fr;
      }
    }
   }
  }
  
}
