.User_Login {
  min-height: 100vh;
  background: var(--background-color);
  font-family: "Poppins";
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 gap:1rem;

  @media (max-width: 1000px){
   
  
 height: 80vh;
  }
  
}

.User_LoginContainer {
 
  // margin: auto;
  background-color: var(--white-color);
  border-right: 13px solid var(--tertiary-color);
  border-radius: 0.3125rem;
  padding: 2rem 1.2rem;

  
   width: 90%;
   max-width: 600px;
 display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}
.pageTitle_login {
  color: var(--background-color);
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: clamp(1.1rem, 2vw, 1.185rem);
  align-items: center;
  margin: 0.5rem 0;
}
.loginForm {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.75rem;
  width: 100%;

}
.rememberSection {
  display: flex;
  flex-direction: row;
  grid-gap: clamp(0.5rem, 2vw, 0.8rem);
  align-items: center;
  padding-top: clamp(0.8rem, 2vw, 2rem);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
}
.rememberChecked {
  flex: 1;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: clamp(0.86rem, 2vw, 0.875rem);
  line-height: 18px;
  color: var(--text-color);
  text-align: justify;
  white-space: nowrap;
}
.forgetPassword {
  color: var(--tertiary-color);
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: clamp(0.8rem, 2vw, 0.875rem);
  line-height: 18px;
  text-align: justify;
  text-decoration: none;
  white-space: nowrap;
}

// Media Queries
// @media (min-width: 41.25rem) {
//   .User_LoginContainer {
//     width: unset;
//     width: 58%;
//     padding: unset;
//     padding: 20px 30px;
//   }
// }
// @media (min-width: 50.875rem) {
//   .User_LoginContainer {
//     width: unset;
//     width: 55%;
//     padding: 50px 50px;
//   }
// }
// @media (min-width: 60.25rem) {
//   .User_LoginContainer {
//     width: 45%;
//   }

// }
