.new_Support {
 
  a {
    text-decoration: none;
  }

  .newContainer_support {
    flex: 6; 
   

    .top_support,
    .bottom_support {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;

      h1 {
        color: var(--background-color);
        font-size: 20px;
      }

      .left_support {
        text-align: center;

        form {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
          gap: 30px;

          .message-box {
            display: block;
            label {
              display: block;
              text-align: left;
              font-size: 0.9rem;
              margin-bottom: 0.3rem;
            }
            textArea {
              padding-left: 1rem;
              padding-right: 1rem;
              width: 100%;
              height: 6rem;
              border-radius: 4px;
              border: 1px solid grey;
            }
          }
        }

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
        button {
          width: 150px;
          padding: 10px;
          border: none;
          background-color: teal;
          display: block;
          color: white;
          font-weight: bold;
          cursor: pointer;
          margin-top: 10px;
          display: block;
          align-self: flex-end;
          justify-self: flex-end;
        }
      }

      .right {
        form {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
          gap: 30px;

          .formInput {
            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            .addon {
              display: flex;
              align-items: center;

              p {
                padding: 0.28rem;
                font-size: 0.97rem;
                color: #fff;
                background-color: #0080ff;
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
              }
              input {
                border: 1px solid #2a5581;
              }
            }
            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
            }
            select {
              width: 100%;
              padding: 0.5rem;
            }
          }
        }
      }
    }
    .top_support {
      display: block;
      input {
        margin-left: 1rem;
      }
      h1 {
        text-align: center;
      }
      .info-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ip-card {
          h3 {
            font-size: 1rem;
          }
          p {
            font-size: 0.8rem;
            width: 35rem;
            margin: 0.7rem 0;
          }
          .alert {
            color: red;
          }
        }
      }

    }
    .bottom_support {
      padding: 3rem;
      form{
        width: 100%;
      }
    }
  }
}
