.admin-contain{
        width: calc(100vw - 200px);
        margin-left: 200px;
        min-height: 100vh;
        padding-top: 50px;
        @media (max-width: 1000px){
            margin-left: 0;
            min-height: auto;
            width: 100%;
          }
   
}