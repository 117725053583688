.AdminNavbar {
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 20;
    background-color: #fff;
  @media (min-width: 1000px) {
    display: none;
  }
    .wrapper_nav {
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .search {
        display: flex;
        align-items: center;
        border: 0.5px solid lightgray;
        padding: 3px;
  
        input {
          border: none;
          outline: none;
          background: transparent;
  
          &::placeholder {
            font-size: 12px;
          }
        }
      }
  
      .items {
        display: flex;
        align-items: center;
  
        .item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          position: relative;
  
          .icon {
            font-size: 20px;
          }
  
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
  
          .counter {
            width: 15px;
            height: 15px;
            background-color: red;
            border-radius: 50%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-weight: bold;
            position: absolute;
            top: -5px;
            right: -5px;
          }
        }
      }
    }
  }
  