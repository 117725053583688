.alert-div {
  clear: both;
  .icon {
    color: red;
    font-size: 3rem;
  }
  h3 {
    margin: 1rem;
  }
  .alert-card {
    box-shadow: 0px 10px 15px 3px rgba(0, 0, 0, 0.1);
    max-width: 27rem;
    width: 80%;
    margin: auto;
    margin-top: 10rem;
    padding: 3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  button {
    padding: 0.4rem 1rem;
    font-size: 1rem;
    align-self: flex-end;
    margin-top: 1rem;
  }
}
